import { LogEvent, TimingEvent } from "../types/Analytics";
import zoLogger from "../utils/helpers/zoLogger";
import BaseAnalytics from "./BaseAnalytics";
import { sendGTMEvent } from '@next/third-parties/google'

declare global {
    interface Window {
        gtag: any,
        ga: any,
        AF_SMART_SCRIPT: any,
        kwaiq: any
    }
}

class GoogleAnalytics extends BaseAnalytics {

    logEvent(event: LogEvent, args:Object) {
        zoLogger.log("INSIDE WinZO GoogleAnalytics logEvent", event, args)
        const {action} = event;
        sendGTMEvent({event: action, eventType: 'ga', ...args})
    }

    timingEvent(event: TimingEvent) {
        zoLogger.log("INSIDE WinZO GoogleAnalytics timing event", event)
    }
}

export default GoogleAnalytics
