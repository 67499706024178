import {LogEvent, TimingEvent} from "../types/Analytics";
import zoLogger from '../utils/helpers/zoLogger';
import BaseAnalytics from "./BaseAnalytics";
import GoogleAnalytics from "./GoogleAnalytics";

class Analytics {
    private static instance: Analytics
    private readonly googleAnalytics: GoogleAnalytics;
    private analyticsList = Array<BaseAnalytics>()

    constructor() {
        this.googleAnalytics = new GoogleAnalytics()
        this.analyticsList.push(this.googleAnalytics)
    }

    public static getInstance(): Analytics {
        if (!this.instance) {
            this.instance = new Analytics()
        }
        return this.instance
    }

    logEvent(event: LogEvent, args:Object = {}) {
        this.analyticsList.map(analytics => {
            return analytics.logEvent(event, args)
        })
    }

    timingEvent(event: TimingEvent) {
        this.analyticsList.map(analytics => {
            return analytics.timingEvent(event)
        })
    }
}

export default Analytics
