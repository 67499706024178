import {IMappingObjectData, IMappingObjectDataSubPages, ISubPageLocaleData} from "../../types/common";
import {isDevelopmentEnv, WINZO_DOT_GAMES_URL, WINZO_GLOBAL_URL} from "./EnvironmentConstants";
import {
    allLocales,
    BRAZIL_LOCALES,
    COLOMBIA_LOCALES,
    CONTINENT,
    COUNTRY,
    GERMANY_LOCALES,
    INDIA_LOCALES,
    LANGUAGE_CONTINENT,
    LANGUAGE_COUNTRY,
    localeArrayMinLanguage,
    MEXICO_LOCALES,
    US_LOCALES
} from "./LocaleAndCountryConstants";

export const FACEBOOK_DOMAIN_VERIFICATION = {
    [WINZO_DOT_GAMES_URL]: "o4cq50h7mfw7n9ydd4jatsgta957we",
    [WINZO_GLOBAL_URL]: "dh2mhsabfk9sfwvkt3pe4wlyjjsmxx",
    DEFAULT: "72iv9j8hobe5obgkw3sd26e17ggu3p"
}

export const UTM_AUTO_DOWNLOAD = "auto-download"
export const UTM_HOTSTAR = "hotstar"
export const UTM_PLAYSTORE = "playstore" 


export const UTM_FB = "fb"
export const UTM_SHARE_CHAT = "sharechat"
export const UTM_TRUECALLER = "tc"
export const UTM_GAW = "gaw"

export const THEME_DARK = "dark"
export const BOOL_FALSE = "false"

export const FIXED_DOWNLOAD_BUTTON_TYPE = "fixed"
export const STATIC_DOWNLOAD_BUTTON_TYPE = "static"
export const HEADER_DOWNLOAD_BUTTON_TYPE = "header"
export const BANNER_DOWNLOAD_BUTTON_TYPE = "banner"
export const BADGE_DOWNLOAD_BUTTON_TYPE = "badge"
export const GDC_HEADER_TYPE = "gdcHeader"
export const BRAZIL_SUPERSTAR_HEADER = "brazilSuperstarHeader"
export const BUTTON_TYPE_LINK = "buttonLink"
export const IOS = "ios"

export const META_DESC_CONTENT = "Download Bharat's Largest Real Cash gaming pro app to play fantasy sports, rummy, poker & 70  games. Play online games with 3 Crore  Players. ✓Win upto 10 Crore Daily ✓Withdraw Cash Instantly✓100%Safe & Secure"
export const META_KEYWORDS_CONTENT = "winzo, winzo gold, win, free cash, cricket, fantasy cricket, rummy, win cash"
export const PAGE_TITLE = "WinZO - Play Cricket, Rummy, Fantasy Sports & 70+ Games on India's Largest Real Cash Gaming App - Win Cash Daily!"

// boolean to handle dcmn script
export const isDcmnEnabled = false;
export const BANNER_BOTTOM_HEIGHT = 45;

//Sms related contants
export const SENDING_MSG = "Sending link on";
export const ERROR_MSG = "Error while sending message on";

export const VariantA = "Variant_A"
export const VariantB = "Variant_B"

//Footer constants
export const CAREER_KEY = "CAREER_KEY";
export const DOWNLOAD_KEY = "DOWNLOAD_KEY";
export const BUG_BOUNTY = "BUG_BOUNTY";

//Page constants
export const HOME_PAGE = "homePage";
export const GET_APP_PAGE = "getAppPage";
export const GAME_PAGE = "gamePage";
export const GET_APP_META_PAGE = "getAppMetaPage";
export const LESS_CLUSTERED: string = "lessClusteredPage";

export const GAMES_TO_SHOW_INITIALLY = 8;
export const GAMES_TO_SHOW_INITIALLY_V2 = 2;
export const CATEGROY_GAME_TO_SHOW_INITIALLY = 4;
export const OFFER_POPUP_DELAY = 90000;
export const APK_DOWNLOAD_AGAIN_DELAY = 10000;
export const GET_APP_ONLINK_CODE = "D4jJ";

// All Page URLs

export const HOME_PAGE_URL = "/";

// Get App Page URLS
export const GET_APP_URL = "get-app";
export const GET_APP_MANIA_PLAY_URL = "get-app-mania-play";
export const GET_APP_REF_URL = "get-app-ref";
export const GET_APP_AF_URL = "get-app-af";
export const GET_APP_AF2_URL = "get-app-af2";
export const GET_APP_FB_URL = "get-app-fb";
export const GET_APP_META_URL = "get-app-meta";
export const GET_APP_FACEBOOK_URL = "get-app-facebook";
export const GET_APP_PLAY_URL = "get-app-play";
export const GET_APP_SNAPCHAT_URL = "get-app-sc";
export const GET_APP_KWAI_URL = "get-app-kwai";
export const GET_APP_TIKTOK_URL = "get-app-tiktok";


// Other Pages URLS
export const SUPERSTAR_URL = "superstar"
export const BHARATTECH_TRIUMPH_PROGRAM_URL = "bharattech-triumph-program"
export const BHARATTECH_TRIUMPH_PROGRAM_2 = "bharattech-triumph-program-season2"
export const BHARATTECH_TRIUMPH_PROGRAM_3 = "bharattech-triumph-program-season3"
export const THE_TECH_TRIUMPH_URL = "the-tech-triumph"
export const SUPERSTAR_ONBOARDING = "superstar-onboarding"
export const SUPERSTAR_TUTORIALS = "superstar-tutorials";
export const SUPERSTAR_RESOURCES = "superstar-resources";
export const SUPERSTAR_DASHBOARD = "superstar-dashboard"
export const WINZO_STORE_URL = "winzo-store";
export const TERMS_CONDITIONS_URL = "terms-conditions";
export const KYC_POLICY_URL = "kyc-policy";
export const FAIRPLAY_POLICY = "fairplay-policy";
export const FAIRPLAY_POLICY_V2 = "fairplay-policy-v2";
export const GDC_TERMS_AND_CONDITIONS = "gdc-terms-conditions";
export const TECH_TRIUMPH_TERMS_AND_CONDITIONS = "tech-triumph-terms-conditions";
export const COMMUNITY_GUIDELINES = "community-guidelines"
export const TERMS_OF_USE_URL = "terms-of-use";
export const TERMS_OF_SERVICE_URL = "terms-of-service";
export const SUPERSTAR_TERMS_CONDITION_URL = "superstar-terms-conditions";
export const CARICATURE_TERMS_CONDITION_URL = "player-artwork-terms-and-conditions"
export const REFUND_POLICY_URL = "refund-policy";
export const PRIVACY_POLICY_URL = "privacy-policy";
export const RESPONSIBLE_GAMING_POLICY = "responsible-gaming-policy";
export const CONTACT_US_URL = "contact-us";
export const CALL_BREAK_URL = "call-break";
export const WORLD_WAR_URL = "world-war";
export const PLAYER_XCHANGE_URL = "player-xchange"
export const ALL_GAMES_URL = "all-games";
export const CATEGORY_URL = "category";
export const RNG_CERTIFICATE_URL = "certification"
export const ANTI_MONEY_LAUNDERING_POLICY = 'anti-money-laundering-policy';
export const PLAYSTORE_PAGE = "ps";
export const PLAYSTORE_PAGE2 = "ps2";
export const PLAYSTORE_FB_PAGE = "ps-fb";
export const BRAND_GUIDELINE_PAGE = "guideline";
export const GAME_DOWNLOAD = "game-download";
export const WINZO_APK_DOWNLOAD = "winzo-apk-download";
export const FREE_GAMES = "free-games";
export const APP_DOWN = "app-down";
export const NOT_FOUND_URL = "404";
export const TEAM_TOURNAMENT_URL = "team-tournament";
export const SUPPORT_PRIVACY_POLICY = "support/privacy-policy";
export const SUPPORT_TERMS_CONDITIONS = "support/terms-conditions";
export const SPINNER = "spinner";
export const THE_TECH_TRIUMPH_PRIVACY_POLICY = THE_TECH_TRIUMPH_URL + "/" + PRIVACY_POLICY_URL;

// URL Path
export const ONBOARDING_PATH = "onboarding"
export const TUTORIALS_PATH = "tutorials"
export const RESOURCES_PATH = "resources"
export const SUPPORT_PATH = "support"
export const BRAND_PATH = "brand"
export const MOBILE_APP_PATH = "mobile-app"

// Game Page URLS
export const RUMMY_GAME_PAGE = 'rummy';
export const FANTASY_CRICKET_GAME_PAGE = 'fantasy-cricket';
export const POKER_GAME_PAGE = 'poker';
export const POOL_GAME_PAGE = 'pool';
export const CARROM_GAME_PAGE = 'carrom';
export const BINZO_GAME_PAGE = 'binzo';
export const JACKS_CARD_GAME_PAGE = 'jacks-card-game';
export const KANCHEY_GAME_PAGE = 'kanchey-game';
export const FRUIT_SAMURAI_GAME_PAGE = 'fruit-samurai';
export const LUDO_GAME_PAGE = 'ludo';
export const CALL_BREAK_GAME_PAGE = 'call-break-game';
export const WCC_GAME_PAGE = 'wcc';
export const CLASH_ROYALE_GAME_PAGE = 'clash-royale';
export const CONNECT_FOUR_GAME_PAGE = 'connect-four';
// export const BINGO_GAME_PAGE = 'bingo-game';
export const CHESS_GAME_PAGE = 'chess';
export const ARCHERY_GAME_PAGE = 'archery-game';
export const TWENTY_NINE_CARD_GAME_PAGE = 'twenty-nine-card-game';
export const BUBBLE_SHOOTER_GAME_PAGE = 'bubble-shooter';
export const SOLITAIRE_GAME_PAGE = 'solitaire-game';
export const BASKETBALL_GAME_PAGE = 'basketball-game';
export const FANTASY_FOOTBALL_GAME_PAGE = 'fantasy-football';
export const METRO_SURFER_GAME_PAGE = 'metro-surfer';
export const DOTS_AND_BOXES_GAME_PAGE = 'dots-and-boxes';
export const COURT_PIECE_GAME_PAGE = 'court-piece';
export const DEHLA_PAKAD_GAME_PAGE = 'dehla-pakad';
export const MR_RACER_GAME_PAGE = 'mr-racer';
export const SNAKES_AND_LADDERS_GAME_PAGE = 'snakes-and-ladders';
export const FREECELL_GAME_PAGE = 'freecell';
export const ESPORTS_GAME_PAGE = 'esports';
export const CANDY_MATCH = 'candy-match';
export const CHECKERS = 'checkers';
export const MATCH_3D_MANIA = 'match-3d-mania';
export const WORD_SEARCH = 'word-search';
export const CROWD_CITY = 'crowd-city';
export const LIQUID_SORT = 'liquid-sort';
export const SITEMAP_PAGE = 'sitemap';
export const FAQ_PAGE = 'faqs';
export const TRUCO = 'truco';
export const BURACO = 'buraco';
export const CRASH_GAME = 'crash-game';
export const TWO_ZERO_FOUR_EIGHT_BALLS = '2048-balls';
export const ALIEN_ATTACK = 'alien-attack';
export const CRAZY_MONSTERS = 'crazy-monsters';
export const BALL_RACE = 'ball-race';
export const BEAR_RUN = 'bear-run';
export const CARDJACK_PUZZLE = 'cardjack-puzzle';
export const BLOCK_SMASHER = 'block-smasher';
export const BRICKY_BLITZ = 'bricky-blitz';
export const CIRCLE_CARROM = 'circle-carrom';
export const CRICKET = 'cricket';
export const DEAD_KILL = 'dead-kill';
export const DUNE = 'dune';
export const FINGERS = 'fingers';
export const FREE_FIRE_MAX = 'free-fire-max';
export const FREE_STYLE_CARROM = 'freestyle-carrom';
export const SLICE_MASTER = 'slice-master';
export const GUNS_AND_BOTTLES = 'guns-and-bottles';
export const KNIFE_UP = 'knife-up';
export const MATCH_BATTLE = 'match-battle';
export const MINE_RUNNER = 'mine-runner';
export const PENALTY_SHOOT = 'penalty-shoot';
export const PLANE_VS_MISSILE = 'plane-vs-missile';
export const SHEEP_BATTLE = 'sheep-battle';
export const SNAKE_RUSH = 'snake-rush';
export const SPACE_HUNTER = 'space-hunter';
export const SPACE_WARRIOR = 'space-warrior';
export const STREET_FIGHT = 'street-fight';
export const STUMBLE_GUYS = 'stumble-guys';
export const STUMP_IT = 'stump-it';
export const STUPID_BIRDS = 'stupid-birds';
export const TAP_TAP = 'tap-tap';
export const TENNIS_TOONS = 'tennis-toons';
export const TRUMP_CARDS = 'trump-cards';
export const WORLD_OF_TENNIS = 'world-of-tennis';
export const FOOTBALL_FREEKICK = 'football-freekick';
export const ONO = 'ono';
export const DOMINOES_GAME = 'dominoes-game';
export const HEARTS_GAME= 'hearts-game';

// Subpages URLS
export const TRICKS_SUBPAGE = 'tricks';
export const RULES_SUBPAGE = 'rules';
export const DOWNLOAD_SUBPAGE = 'download';
export const EARNING_APP_SUBPAGE = "earning-app";
export const DOUBLE_FREECELL_SUBPAGE = 'double-freecell'
export const HOWTOPLAY_SUBPAGE = 'how-to-play';
export const INDIAN_RUMMY = "indian-rummy";
export const THIRTEEN_CARDS = "13-cards";
export const CASH_GAME = "cash-game";
export const SETS = "sets";
export const POOL_RUMMY = "pool-rummy";
export const POINTS = "points";
export const GAME_DETAIL_FOLDER = 'gameDetail/';
export const HELP = 'help';
export const GAME_21_CARD_RUMMY = '21-card-rummy';
export const DEALS_RUMMY_VS_POINTS_RUMMY = 'deals-rummy-vs-points-rummy';
export const HOW_TO_PLAY_CASH_RUMMY = 'how-to-play-cash-rummy';
export const HOW_TO_PLAY_JOKER_RUMMY = 'how-to-play-joker-rummy';
export const POOL_RUMMY_VS_POINTS_RUMMY = 'pool-rummy-vs-points-rummy';
export const REAL_CASH_RUMMY = 'real-cash-rummy';
export const TYPES_OF_RUMMY = 'types-of-rummy';
export const RUMMY_VS_SOLITAIRE = 'rummy-vs-solitaire';
export const RUMMY_JOKER = 'rummy-joker';
export const ODI_CRICKET = 'odi-cricket';
export const TEST_CRICKET = 'test-cricket';
export const T20_CRICKET = 't20-cricket';
export const POINT_SYSTEM = 'point-system';
export const ROHIT_SHARMA_VS_VIRAT_KOHLI = 'rohit-sharma-vs-virat-kohli';
export const MS_DHONI_VS_VIRAT_KOHLI = 'ms-dhoni-vs-virat-kohli';
export const VIRAT_KOHLI_VS_BABAR_AZAM = 'virat-kohli-vs-babar-azam';
export const VIRAT_KOHLI_VS_AB_DE_VILLIERS = 'virat-kohli-vs-ab-de-villiers';
export const RISHABH_PANT_VS_ISHAN_KISHAN = 'rishabh-pant-vs-ishan-kishan';
export const INTERNATIONAL_CRICKET = 'international-cricket';
export const REAL_MONEY = 'real-money';
export const MULTIPLAYER = 'multiplayer';
export const OKLAHOMA_RUMMY = 'oklahoma-rummy';
export const RUMMY_VS_CALLBREAK = 'rummy-vs-callbreak';
export const THIRTEEN_CARD_RUMMY_VS_21_CARD_RUMMY = '13-card-rummy-vs-21-card-rummy';
export const MOHAMMAD_RIZWAN_VS_VIRAT_KOHLI = 'mohammad-rizwan-vs-virat-kohli';
export const SACHIN_TENDULKAR_VS_VIRAT_KOHLI = 'sachin-tendulkar-vs-virat-kohli';
export const BABAR_AZAM_VS_ROHIT_SHARMA = 'babar-azam-vs-rohit-sharma';
export const TOURNAMENT = 'tournament';
export const TYPES_OF_LUDO = 'types-of-ludo';
export const TYPES_OF_CARROM = 'types-of-carrom';
export const INDIAN_LUDO = 'indian-ludo';
export const LUDO_CASH = 'ludo-cash';
export const LUDO_CONTEST = 'contest';
export const LUDO_DICE = 'dice';
export const LUDO_MONEY = 'money';

// category pages url
export const ACTION_GAME = 'action-games'
export const SPORTS_GAME = 'sports-games'
export const STRATEGY_GAME = 'strategy-games'
export const CASUAL_GAME = 'casual-games'

export const ARCADE_GAME = 'arcade-games'
export const RACING_GAME = 'racing-games'
export const CARD_GAME = 'card-games'
export const BOARD_GAME = 'board-games'
export const GDC_WEB_URL = "https://console.winzogames.com/";
export const SAME_ORIGIN_SITE = "same-origin";
export const LANGUAGE_TOGGLE = "languageToggle";
export const COMMON = "common";
export const HOW_TO_PLAY_PAGES = "howToPlayPages";
export const META_PAGE = "metaPage";
export const PHONEPE_PAGE = "phonePePage";
export const ESPORTS_GAME = "esportGames"
export const PLAYER_XCHANGE_PAGE = "playerXChange"
export const STOCK_DETAILS_PAGE = "stock-details"
export const TEAM_STOCKS_PAGE = "team-stocks"
export const TEAM_STOCKS_WIN_PROBABILITY_PAGE = "team-stocks-win-probability"
export const AB_TESTING_CONFIG = "abTestingConfig";
export const DEEP_LINK_JSON = "deep-link";
export const DEVICE_MODEL_JSON = "device-model";

export const DEFAULT_LOCALE = "default";
export const GAME_PAGES = "gamePages";
export const SUB_GAME_PAGES = "subGamePages";

//How to play pages
export const howToPlayPages = {
    PUBG: "pubg",
    COD: "cod",
    CLASH_ROYALE: "clashRoyale",
    LOTTERY: "lottery",
    SOCIAL_FANTASY: "socialFantasy",
    STUMBLE_GUYS: "stumbleGuys",
    BATTLE_LANDS_ROYALE: "battlelandsRoyale",
    BOMB_SQUAD: "bombsquad",
    POKEMON: "pokemon",
    BULLET_LEAGUE: "bulletLeague",
    DAILY_PUZZLES: "dailyPuzzles",
    MATCH_THE_SCORE: "matchTheScore",
    POINT_RUMMY: "pointRummy",
    LUDO: "ludo",
    WORLD_WAR: "worldWar",
    EVENT_PREDICTION: "eventPrediction",
    SNAKE_AND_LADDERS_S2: "snakes&ladders2",
    FREE_FIRE: "freefire",
    FREE_FIRE_MAX: "freefireMax",
    BGMI: "bgmi",
    FANTASY_FOOTBALL: "fantasy-football",
    PLAYER_EXCHANGE_FOOTBALL: "playerExchange-football",
    ARENA_TOURNAMENT_RULES_VIDEO: "arena-tournament-rules-video",
}

export const helpPages = {
    GRIEVANCE_REDRESSAL: "grievance-redressal",
}

export const indiaLocaleObject: IMappingObjectData = {
    locales: INDIA_LOCALES
}

const defaultSubPagesMinLocale: ISubPageLocaleData[] = [
    {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
    {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
    {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
    {subPage: TRICKS_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}}
]

export const defaultSubPageLocaleObject = {
    subPages: [
        {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
        {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
        {subPage: RULES_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
        {subPage: TRICKS_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}}
    ]
}

export const gamePagesWithLocale = new Map<string, IMappingObjectData>([
    [RUMMY_GAME_PAGE, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES]}],
    [FANTASY_CRICKET_GAME_PAGE, indiaLocaleObject],
    [POKER_GAME_PAGE, indiaLocaleObject],
    [POOL_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [CARROM_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES]}],
    [FRUIT_SAMURAI_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [LUDO_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [CALL_BREAK_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [WCC_GAME_PAGE, indiaLocaleObject],
    [CONNECT_FOUR_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [CHESS_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [BINZO_GAME_PAGE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...GERMANY_LOCALES]}],
    [KANCHEY_GAME_PAGE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN]}],
    [JACKS_CARD_GAME_PAGE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN]}],
    [ARCHERY_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [TWENTY_NINE_CARD_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [BUBBLE_SHOOTER_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES, ...GERMANY_LOCALES]}],
    [SOLITAIRE_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES, ...GERMANY_LOCALES]}],
    [BASKETBALL_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [FANTASY_FOOTBALL_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [METRO_SURFER_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [DOTS_AND_BOXES_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [COURT_PIECE_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [DEHLA_PAKAD_GAME_PAGE, indiaLocaleObject],
    [MR_RACER_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [SNAKES_AND_LADDERS_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [FREECELL_GAME_PAGE, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES]}],
    [CROWD_CITY, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [LIQUID_SORT, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [CANDY_MATCH, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [CHECKERS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [MATCH_3D_MANIA, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [WORD_SEARCH, {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}],
    [CRASH_GAME, {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}],
    [TRUCO, {locales: BRAZIL_LOCALES}],
    [BURACO, {locales: BRAZIL_LOCALES}],
    [TWO_ZERO_FOUR_EIGHT_BALLS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [ALIEN_ATTACK, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [CRAZY_MONSTERS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [BALL_RACE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [BEAR_RUN, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [CARDJACK_PUZZLE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [BRICKY_BLITZ, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES, ...GERMANY_LOCALES]}],
    [BLOCK_SMASHER, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [CIRCLE_CARROM, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [CRICKET, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [DEAD_KILL, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [DUNE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [FINGERS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [FREE_FIRE_MAX, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [FREE_STYLE_CARROM, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [SLICE_MASTER, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [GUNS_AND_BOTTLES, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [KNIFE_UP, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [MINE_RUNNER, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [MATCH_BATTLE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [PENALTY_SHOOT, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [PLANE_VS_MISSILE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [SHEEP_BATTLE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [SNAKE_RUSH, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [SPACE_HUNTER, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [SPACE_WARRIOR, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [STREET_FIGHT, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [STUMBLE_GUYS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [STUMP_IT, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [STUPID_BIRDS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [TAP_TAP, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [TENNIS_TOONS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [TRUMP_CARDS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN]}],
    [WORLD_OF_TENNIS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...BRAZIL_LOCALES]}],
    [HEARTS_GAME, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN]}],
    [DOMINOES_GAME, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, LANGUAGE_COUNTRY.PT_BR, LANGUAGE_COUNTRY.EN_BR]}],
    [ONO, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN]}],
    [FOOTBALL_FREEKICK, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN, ...GERMANY_LOCALES]}],
]);
export const categoryPagesArray = new Map<string, IMappingObjectData>([
    [ACTION_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [SPORTS_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [STRATEGY_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [CASUAL_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [ARCADE_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [RACING_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [CARD_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [BOARD_GAME, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}]
])

export const subPageGamePagesWithLocale = new Map<string, IMappingObjectDataSubPages>([
    [LUDO_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: EARNING_APP_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: TYPES_OF_LUDO, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TOURNAMENT, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: INDIAN_LUDO, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: LUDO_CASH, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: LUDO_CONTEST, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: LUDO_DICE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: LUDO_MONEY, localeData: {locales: [...localeArrayMinLanguage]}},
        ]
    }],
    [BUBBLE_SHOOTER_GAME_PAGE, defaultSubPageLocaleObject],
    [CARROM_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}},
            {subPage: EARNING_APP_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TYPES_OF_CARROM, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TOURNAMENT, localeData: {locales: [...localeArrayMinLanguage]}},
        ]
    }],
    [POOL_GAME_PAGE, {
        subPages:[
            {subPage:RULES_SUBPAGE, localeData: {locales: [...INDIA_LOCALES,LANGUAGE_COUNTRY.PT_BR,LANGUAGE_COUNTRY.EN_BR]}},
            {subPage:DOWNLOAD_SUBPAGE, localeData: {locales: [...INDIA_LOCALES,LANGUAGE_COUNTRY.PT_BR,LANGUAGE_COUNTRY.EN_BR]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}}
        ]
    }],
    [DOMINOES_GAME, {
        subPages:[
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [LANGUAGE_COUNTRY.PT_BR,LANGUAGE_COUNTRY.EN_BR]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [LANGUAGE_COUNTRY.PT_BR,LANGUAGE_COUNTRY.EN_BR]}},
        ]
    }],
    [CALL_BREAK_GAME_PAGE, {
        subPages: [
            ...defaultSubPageLocaleObject.subPages,
            {subPage: EARNING_APP_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: REAL_MONEY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: MULTIPLAYER, localeData: {locales: [...localeArrayMinLanguage]}},
        ]
    }],
    [RUMMY_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: INDIAN_RUMMY, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: THIRTEEN_CARDS, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: CASH_GAME, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: SETS, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: POOL_RUMMY, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: POINTS, localeData: {locales: [...INDIA_LOCALES]}},
            {subPage: GAME_21_CARD_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: DEALS_RUMMY_VS_POINTS_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: POOL_RUMMY_VS_POINTS_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: REAL_CASH_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TYPES_OF_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RUMMY_VS_SOLITAIRE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RUMMY_JOKER, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: OKLAHOMA_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RUMMY_VS_CALLBREAK, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: THIRTEEN_CARD_RUMMY_VS_21_CARD_RUMMY, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TOURNAMENT, localeData: {locales: [...localeArrayMinLanguage]}}
        ]
    }],
    [COURT_PIECE_GAME_PAGE, defaultSubPageLocaleObject],
    [SNAKES_AND_LADDERS_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TOURNAMENT, localeData: {locales: [...localeArrayMinLanguage]}}
        ]
    }],
    [CONNECT_FOUR_GAME_PAGE, {
        subPages: defaultSubPagesMinLocale
    }],
    [FANTASY_FOOTBALL_GAME_PAGE, {
        subPages: defaultSubPagesMinLocale
    }],
    [CHECKERS, {
        subPages: defaultSubPagesMinLocale
    }
    ],
    [SOLITAIRE_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}}
        ]
    }],
    [WCC_GAME_PAGE, {
        subPages: defaultSubPagesMinLocale
    }],
    [CHESS_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}}
        ]
    }],

    [FREECELL_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}},
            {subPage: DOUBLE_FREECELL_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage, ...BRAZIL_LOCALES]}}
        ]
    }],
    [FANTASY_CRICKET_GAME_PAGE, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: ODI_CRICKET, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TEST_CRICKET, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: T20_CRICKET, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: POINT_SYSTEM, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: ROHIT_SHARMA_VS_VIRAT_KOHLI, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: MS_DHONI_VS_VIRAT_KOHLI, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: VIRAT_KOHLI_VS_BABAR_AZAM, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: VIRAT_KOHLI_VS_AB_DE_VILLIERS, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RISHABH_PANT_VS_ISHAN_KISHAN, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: INTERNATIONAL_CRICKET, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: EARNING_APP_SUBPAGE, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: MOHAMMAD_RIZWAN_VS_VIRAT_KOHLI, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: SACHIN_TENDULKAR_VS_VIRAT_KOHLI, localeData: {locales: [...localeArrayMinLanguage]}},
            {subPage: BABAR_AZAM_VS_ROHIT_SHARMA, localeData: {locales: [...localeArrayMinLanguage]}},
        ]
    }],
    [TRUCO, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
        ]
    }],
    [BURACO, {
        subPages: [
            {subPage: DOWNLOAD_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: HOWTOPLAY_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: RULES_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
            {subPage: TRICKS_SUBPAGE, localeData: {locales: [...BRAZIL_LOCALES]}},
        ]
    }],
]);

export const getGetAppPagesWithLocales = new Map<string, IMappingObjectData>([
    [GET_APP_URL, {locales: [LANGUAGE_COUNTRY.EN_IN, ...US_LOCALES, ...GERMANY_LOCALES, ...BRAZIL_LOCALES]}],
    [GET_APP_MANIA_PLAY_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [GET_APP_REF_URL, {locales: [LANGUAGE_COUNTRY.EN_IN, ...US_LOCALES, ...GERMANY_LOCALES, ...BRAZIL_LOCALES]}],
    [GET_APP_AF_URL, {locales: [LANGUAGE_COUNTRY.EN_IN, ...US_LOCALES, ...GERMANY_LOCALES, ...BRAZIL_LOCALES]}],
    [GET_APP_PLAY_URL, {locales: [LANGUAGE_COUNTRY.EN_IN, ...US_LOCALES, ...GERMANY_LOCALES, ...BRAZIL_LOCALES]}],
    [GET_APP_SNAPCHAT_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [GET_APP_KWAI_URL, {locales: [LANGUAGE_COUNTRY.PT_BR]}],
    [GET_APP_TIKTOK_URL, {locales: [...BRAZIL_LOCALES]}]
])

export const getGetAppFacebookPagesWithLocales = new Map<string, IMappingObjectData>([[
    GET_APP_FB_URL, {
      locales: [
        LANGUAGE_COUNTRY.EN_IN,
        LANGUAGE_COUNTRY.HI_IN,
        LANGUAGE_COUNTRY.ML_IN,
        LANGUAGE_COUNTRY.TA_IN,
        LANGUAGE_COUNTRY.KN_IN,
        ...US_LOCALES,
        ...GERMANY_LOCALES,
        ...BRAZIL_LOCALES,
      ],},
  ],[
    GET_APP_FACEBOOK_URL, {
      locales: [
        LANGUAGE_COUNTRY.EN_IN,
        LANGUAGE_COUNTRY.PT_BR,
        ...GERMANY_LOCALES,
      ],},
  ],]);

export const playStoreFacebookPagesWithLocales = new Map<string, IMappingObjectData>([
    [PLAYSTORE_PAGE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR,...GERMANY_LOCALES, ...US_LOCALES, ...COLOMBIA_LOCALES]}],
    [PLAYSTORE_PAGE2, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR,...GERMANY_LOCALES, ...US_LOCALES, ...COLOMBIA_LOCALES]}],
    [PLAYSTORE_FB_PAGE, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR, ...GERMANY_LOCALES]}]
])

export const getAppShortPagesWithLocales = new Map<string, IMappingObjectData>([
    [GET_APP_META_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [GET_APP_AF2_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}]
])

export const howToPlayTutorialPagesWithLocales = new Map<string, IMappingObjectData>([
    [howToPlayPages.BATTLE_LANDS_ROYALE, indiaLocaleObject],
    [howToPlayPages.BOMB_SQUAD, indiaLocaleObject],
    [howToPlayPages.COD, indiaLocaleObject],
    [howToPlayPages.CLASH_ROYALE, indiaLocaleObject],
    [howToPlayPages.LUDO, indiaLocaleObject],
    [howToPlayPages.BULLET_LEAGUE, indiaLocaleObject],
    [howToPlayPages.DAILY_PUZZLES, indiaLocaleObject],
    [howToPlayPages.EVENT_PREDICTION, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [howToPlayPages.LOTTERY, indiaLocaleObject],
    [howToPlayPages.MATCH_THE_SCORE, indiaLocaleObject],
    [howToPlayPages.POINT_RUMMY, indiaLocaleObject],
    [howToPlayPages.POKEMON, indiaLocaleObject],
    [howToPlayPages.PUBG, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES]}],
    [howToPlayPages.SNAKE_AND_LADDERS_S2, indiaLocaleObject],
    [howToPlayPages.SOCIAL_FANTASY, indiaLocaleObject],
    [howToPlayPages.STUMBLE_GUYS, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES]}],
    [howToPlayPages.WORLD_WAR, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES]}],
    [howToPlayPages.FREE_FIRE_MAX, indiaLocaleObject],
    [howToPlayPages.BGMI, indiaLocaleObject],
    [howToPlayPages.FREE_FIRE, {locales: [LANGUAGE_COUNTRY.EN_IN, ...BRAZIL_LOCALES, ...US_LOCALES]}],

]);

export const howToPlayESportPagesWithLocales = new Map<string, IMappingObjectData>([
    [howToPlayPages.FREE_FIRE, indiaLocaleObject]
])

export const howToPlayVideoViewPagesWithLocales = new Map<string, IMappingObjectData>([
    [howToPlayPages.FANTASY_FOOTBALL, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [howToPlayPages.PLAYER_EXCHANGE_FOOTBALL, {locales: [...BRAZIL_LOCALES, LANGUAGE_COUNTRY.EN_IN]}],
    [howToPlayPages.ARENA_TOURNAMENT_RULES_VIDEO, {locales: [...INDIA_LOCALES, LANGUAGE_COUNTRY.PT_BR]}],
])


export const playerExchangePages = {
    CRICKET_MATCH: 'cricket-match',
    FOOTBALL_MATCH: 'football-match'
}

export const playerExchangeGamesWithLocales = new Map<string, IMappingObjectData>([
    [playerExchangePages.CRICKET_MATCH, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [playerExchangePages.FOOTBALL_MATCH, {locales: BRAZIL_LOCALES}],
]);
export const playerExchangePagesId = {
    [playerExchangePages.CRICKET_MATCH]: 1,
    [playerExchangePages.FOOTBALL_MATCH]: 2,
}

export const pagesWithLocale = new Map<string, IMappingObjectData>([
    [HOME_PAGE_URL, {locales: allLocales}],
    [SUPERSTAR_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...MEXICO_LOCALES, ...BRAZIL_LOCALES]}],
    [ALL_GAMES_URL, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES, ...GERMANY_LOCALES]}],
    [GAME_DOWNLOAD, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [FREE_GAMES, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [RNG_CERTIFICATE_URL, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES]}],
    [COMMUNITY_GUIDELINES, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES, ...GERMANY_LOCALES, LANGUAGE_CONTINENT.EN_EU]}],
    [CONTACT_US_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES]}],
    [FAIRPLAY_POLICY, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES, ...GERMANY_LOCALES, LANGUAGE_CONTINENT.EN_EU]}],
    [KYC_POLICY_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES, ...MEXICO_LOCALES]}],
    [CARICATURE_TERMS_CONDITION_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES, ...MEXICO_LOCALES]}],
    [PLAYER_XCHANGE_URL, {locales: [...INDIA_LOCALES]}],
    [SITEMAP_PAGE, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES]}],
    [SUPERSTAR_TERMS_CONDITION_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES]}],
    [TERMS_CONDITIONS_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [WINZO_STORE_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES]}],
    [WORLD_WAR_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES]}],
    [ANTI_MONEY_LAUNDERING_POLICY, {locales: allLocales}],
    [BHARATTECH_TRIUMPH_PROGRAM_URL, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES]}],
    [THE_TECH_TRIUMPH_URL, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [TECH_TRIUMPH_TERMS_AND_CONDITIONS, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [GDC_TERMS_AND_CONDITIONS, {locales: [...INDIA_LOCALES]}],
    [BRAND_GUIDELINE_PAGE, {locales: [...INDIA_LOCALES]}],
    [FAQ_PAGE, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES]}],
    [helpPages.GRIEVANCE_REDRESSAL, {locales: [...INDIA_LOCALES]}],
    [PLAYER_XCHANGE_PAGE, {locales: [...INDIA_LOCALES]}],
    [STOCK_DETAILS_PAGE, {locales: [...INDIA_LOCALES]}],
    [TEAM_TOURNAMENT_URL, {locales: [...INDIA_LOCALES]}],
    [TEAM_STOCKS_WIN_PROBABILITY_PAGE, {locales: [...INDIA_LOCALES]}],
    [PRIVACY_POLICY_URL, {locales: allLocales}],
    [REFUND_POLICY_URL, {locales: allLocales}],
    [RESPONSIBLE_GAMING_POLICY, {locales: allLocales}],
    [SUPERSTAR_ONBOARDING, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR]}],
    [SUPERSTAR_TUTORIALS, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR]}],
    [SUPERSTAR_RESOURCES, {locales: [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.PT_BR]}],
    [SUPPORT_PRIVACY_POLICY, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES, ...MEXICO_LOCALES]}],
    [SUPPORT_TERMS_CONDITIONS, {locales: [...INDIA_LOCALES, ...US_LOCALES, ...BRAZIL_LOCALES, ...MEXICO_LOCALES]}],
    [TERMS_OF_SERVICE_URL, {locales: allLocales}],
    [TERMS_OF_USE_URL, {locales: allLocales}],
    [NOT_FOUND_URL, {locales: allLocales}],
    [SPINNER, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [APP_DOWN, {locales: [...INDIA_LOCALES, ...BRAZIL_LOCALES, ...US_LOCALES]}],
    [THE_TECH_TRIUMPH_PRIVACY_POLICY, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
    [WINZO_APK_DOWNLOAD, {locales: [LANGUAGE_COUNTRY.EN_IN]}],
])

export const termsOfUseVersionsArray = {
    [COUNTRY.US]: ["28012023"],
    [COUNTRY.GERMANY]: ["28012023"],
    [COUNTRY.BRAZIL]: ["28012023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["28012023"],
    [COUNTRY.UNITED_KINGDOM]: ["28012023"],
    [COUNTRY.CANADA]: ["28012023"],
    [COUNTRY.SOUTH_AFRICA]: ["28012023"],
    [COUNTRY.NIGERIA]: ["28012023"],
    [COUNTRY.COLOMBIA]: ["28012023"],
    [CONTINENT.EUROPE]: ["28012023"],
}
export const termsOfServiceVersionsArray = {
    [COUNTRY.US]: ["28012023"],
    [COUNTRY.GERMANY]: ["12072024"],
    [COUNTRY.BRAZIL]: ["12072024", "28012023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["28012023"],
    [COUNTRY.UNITED_KINGDOM]: ["28012023"],
    [COUNTRY.CANADA]: ["28012023"],
    [COUNTRY.SOUTH_AFRICA]: ["28012023"],
    [COUNTRY.NIGERIA]: ["28012023"],
    [COUNTRY.COLOMBIA]: ["28012023"],
    [CONTINENT.EUROPE]: ["12072024"],
}
export const privacyPolicyVersionsArray = {
    [COUNTRY.US]: ["21112024","08062023", "28012023"],
    [COUNTRY.GERMANY]: ["08062023", "28012023"],
    [COUNTRY.BRAZIL]: ["15122023", "08062023", "28012023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["08062023"],
    [COUNTRY.UNITED_KINGDOM]: ["08062023", "28012023"],
    [COUNTRY.CANADA]: ["08062023", "28012023"],
    [COUNTRY.SOUTH_AFRICA]: ["08062023", "28012023"],
    [COUNTRY.NIGERIA]: ["08062023", "28012023"],
    [COUNTRY.COLOMBIA]: ["08062023"],
    [CONTINENT.EUROPE]: ["08062023", "28012023"],
}
export const antiMoneyLaunderingPolicyVersionsArray = {
    [COUNTRY.US]: ["15022023"],
    [COUNTRY.GERMANY]: ["15022023"],
    [COUNTRY.BRAZIL]: ["15022023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["15022023"],
    [COUNTRY.UNITED_KINGDOM]: ["15022023"],
    [COUNTRY.CANADA]: ["15022023"],
    [COUNTRY.SOUTH_AFRICA]: ["15022023"],
    [COUNTRY.NIGERIA]: ["15022023"],
    [COUNTRY.COLOMBIA]: ["15022023"],
    [CONTINENT.EUROPE]: ["15022023"],

}
export const refundPolicyVersionsArray = {
    [COUNTRY.US]: ["15022023"],
    [COUNTRY.GERMANY]: ["15022023"],
    [COUNTRY.BRAZIL]: ["15022023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["15022023"],
    [COUNTRY.UNITED_KINGDOM]: ["15022023"],
    [COUNTRY.CANADA]: ["15022023"],
    [COUNTRY.SOUTH_AFRICA]: ["15022023"],
    [COUNTRY.NIGERIA]: ["15022023"],
    [COUNTRY.COLOMBIA]: ["15022023"],
    [CONTINENT.EUROPE]: ["15022023"],
}
export const responsibleGamingPolicyVersionsArray = {
    [COUNTRY.US]: ["15022023"],
    [COUNTRY.GERMANY]: ["15022023"],
    [COUNTRY.BRAZIL]: ["15022023"],
    [COUNTRY.INDIA]: [""],
    [COUNTRY.MEXICO]: ["15022023"],
    [COUNTRY.UNITED_KINGDOM]: ["15022023"],
    [COUNTRY.CANADA]: ["15022023"],
    [COUNTRY.SOUTH_AFRICA]: ["15022023"],
    [COUNTRY.NIGERIA]: ["15022023"],
    [COUNTRY.COLOMBIA]: ["15022023"],
    [CONTINENT.EUROPE]: ["15022023"],
}

// Navigator Platform           Arch type
// armv8l, armv7l        --> 	supports v7a
// aarch64, armv81	     -->    supports v8a
export const supportedCpuArchitecture = {
    bit32: ["armv7", "armv8l"],
    bit64: ["aarch64"],
    apple: ["iPhone", "MacIntel", "iPad"]
}

export const iosAppUrl = {
    [COUNTRY.INDIA]: "https://apps.apple.com/in/app/winzo/id1544750486",
    [COUNTRY.BRAZIL]: "https://apps.apple.com/br/app/id6450958097",
    [COUNTRY.US]: "https://apps.apple.com/us/app/id6450958097"
}

export const SUCCESS = "success"
export const FAILURE = "failure"

export const PAYMENT_MESSAGE = "Transaction is in progress, please check transaction history."
export const BIT_32 = "x32"
export const BIT_64 = "x64"
export const USER_COUNTRY_CODE = "UCC"
export const DEVICE_MODEL = "dm"
export const IP_ADDRESS = "itadd"
export const CLOUDFRONT_USER_DETAILS = "ud"
export const COUNTRY_SELECTED_USING_POPUP = "COUNTRY_SELECTED"
export const NOT_FOUND = "NOT-FOUND"
export const INITIAL = "INITIAL"
export const NOT_IN_LIST = "NOT-IN-LIST"
export const SESSION_TOKENS = {
    SUPERSTAR_LOGIN_SUCCESS: "loginSuccess",
    SUPERSTAR_USER_REGISTERED: "userRegistered",
    SUPERSTAR_REFERRAL_LINK: "referralLink",
    SUPERSTAR_MESSAGE: "superstarMessage",
}
export const SUPERSTAR_API_URLS = {
    REQUEST_OTP: "web-client/requestOtp",
    VERIFY_OTP: "web-client/verifyOtp",
    REGISTER: "web-client/registerUser",
    DASHBOARD: "web-client/dashboard",
    LOGOUT: "web-client/logout"
}

export const SECURITY_HEADERS = {
    "X-XSS-Protection": "1; mode=block",
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff"
}

export const SPIDER_APIS = {
    UPLOAD_FILE: "website/fileUpload",
    SUBMIT_FORM: "website/submitGrievanceForm",
}

export const numberValidationMapping = {
    [COUNTRY.INDIA]: {
        min: 10,
        max: 10
    },
    DEFAULT: {
        min: 7,
        max: 15
    },
};

export const REQUEST_HEADERS = {
    CUSTOM_X_ORIGIN_LOCALE: "x-origin-locale",
    ACCEPT_LANGUAGE: "accept-language",
    CLOUDFRONT_VIEWER_COUNTRY: "cloudfront-viewer-country",
    CLOUDFRONT_IS_MOBILE_VIEWER: "cloudfront-is-mobile-viewer",
    USER_AGENT: "user-agent",
    REFERER: "referer",
    SEC_FETCH_SITE: "sec-fetch-site",
    SEC_CH_UA_MODEL: "Sec-CH-UA-Model",
    CLOUDFRONT_VIEWER_ADDRESS: "CloudFront-Viewer-Address",
    CLOUDFRONT_VIEWER_ASN: "CloudFront-Viewer-ASN",
    CLOUDFRONT_VIEWER_CITY: "CloudFront-Viewer-City",
    CLOUDFRONT_VIEWER_COUNTRY_NAME: "CloudFront-Viewer-Country-Name",
    CLOUDFRONT_VIEWER_COUNTRY_REGION: "CloudFront-Viewer-Country-Region",
    CLOUDFRONT_VIEWER_COUNTRY_REGION_NAME: "CloudFront-Viewer-Country-Region-Name",
    CLOUDFRONT_VIEWER_LATITUDE: "CloudFront-Viewer-Latitude",
    CLOUDFRONT_VIEWER_LONGITUDE: "CloudFront-Viewer-Longitude",
    CLOUDFRONT_VIEWER_METRO_CODE: "CloudFront-Viewer-Metro-Code",
    CLOUDFRONT_VIEWER_POSTAL_CODE: "CloudFront-Viewer-Postal-Code",
    CLOUDFRONT_VIEWER_TIME_ZONE: "CloudFront-Viewer-Time-Zone"
}

export const HEALTH_CHECKER = "HealthChecker";
export const REVALIDATE = isDevelopmentEnv ? 15 : 15 * 60

export const SUPERSTAR_RESOURCES_TAB_STYLE = {
    1: {
      color: "#f70",
      backgroundColor: "rgba(255, 119, 0, 0.08)",
    },
    2: {
      color: "#609c11",
      backgroundColor: "rgba(96, 156, 17, 0.08)",
    },
    3: {
      color: "#9000ff",
      backgroundColor: "rgba(144, 0, 255, 0.08)",
    },
    4: {
      color: "#ff2885",
      backgroundColor: "rgba(255, 40, 133, 0.08)",
    },
  };
