import { LogEvent, TimingEvent } from "../types/Analytics";
import { VariantA } from "../utils/constants/GlobalConstants";

export const HomeBannerDownloadClick: LogEvent = {
  category: "Home_Download",
  action: "Winzo_Gold_Home",
  label: "Download_Detail_Home",
  fbEvent: "Home_APK_Download",
  value: 1,
};

export const HomeBatchDownloadClick: LogEvent = {
  category: "Home_Download_PlayStore",
  action: "Winzo_Gold_Home_PlayStore",
  label: "Download_Detail_Home_PlayStore",
  fbEvent: "Home_APK_Download_PlayStore",
  value: 1,
};

export const HomeFooterDownloadClick: LogEvent = {
  category: "Footer_Download",
  action: "Winzo_Gold_Footer",
  label: "Download_Detail_Footer",
  fbEvent: "Footer_APK_Download",
  value: 1,
};

export const GetAppDownloadClick: LogEvent = {
  category: "GetApp_Download",
  action: "Winzo_Gold_GetApp",
  label: "Download_Detail_GetApp",
  fbEvent: "GetApp_APK_Download",
  value: 1,
};

export const GetAppKwaiDownloadClick: LogEvent = {
  category: "GetApp_Kwai_Download",
  action: "Winzo_Gold_GetApp_Kwai",
  label: "Download_Detail_GetApp_Kwai",
  fbEvent: "GetApp_Kwai_APK_Download",
  value: 1,
};

export const GetAppTikTokDownloadClick: LogEvent = {
  category: "GetApp_TikTok_Download",
  action: "Winzo_Gold_GetApp_TikTok",
  label: "Download_Detail_GetApp_TikTok",
  fbEvent: "GetApp_TikTok_APK_Download",
  value: 1,
};

export const GetAppFBDownloadClick: LogEvent = {
  category: "FB_App_Download_GetApp",
  action: "FB_Winzo_Gold_GetApp",
  label: "FB_Download_Detail_GetApp",
  fbEvent: "FB_APK_Download",
  value: 1,
};

export const GetAppPlayShareChatDownload: LogEvent = {
  category: "ShareChat_App_Download_GetApp",
  action: "ShareChat_Winzo_Gold_GetApp",
  label: "ShareChat_Download_Detail_GetApp",
  fbEvent: "ShareChat_APK_Download",
  value: 1,
};

export const GetAppPlayDownload: LogEvent = {
  category: "GetApp_Play_Download",
  action: "Winzo_Gold_GetApp_Play",
  label: "Download_Detail_GetApp_Play",
  fbEvent: "GetApp_Play_APK_Download",
  value: 1,
};

export const HeaderDownloadClick: LogEvent = {
  category: "Header_Download",
  action: "Winzo_Gold_Header",
  label: "Download_Detail_Header",
  fbEvent: "Header_APK_Download",
  value: 1,
};

export const HomeFixedDownloadClick: LogEvent = {
  category: "Home_Fixed_Download",
  action: "Winzo_Gold_Home_Fixed",
  label: "Download_Detail_Home_Fixed",
  fbEvent: "Home_Fixed_APK_Download",
  value: 1,
};

export const GetAppBannerDownloadClick: LogEvent = {
  category: "GetApp_Banner_Download",
  action: "Winzo_Gold_GetApp_Banner",
  label: "Download_Detail_GetApp_Banner",
  fbEvent: "GetApp_Banner_APK_Download",
  value: 1,
};

export const GetAppKwaiBannerDownloadClick: LogEvent = {
  category: "GetApp_Kwai_Banner_Download",
  action: "Winzo_Gold_GetApp_Kwai_Banner",
  label: "Download_Detail_GetApp_Kwai_Banner",
  fbEvent: "GetApp_Kwai_Banner_APK_Download",
  value: 1,
};

export const GetAppTikTokBannerDownloadClick: LogEvent = {
  category: "GetApp_TikTok_Banner_Download",
  action: "Winzo_Gold_GetApp_TikTok_Banner",
  label: "Download_Detail_GetApp_TikTok_Banner",
  fbEvent: "GetApp_TikTok_Banner_APK_Download",
  value: 1,
};

export const BannedStatePageView = "/banned-state";

export const GetAppFixedShareChatDownload: LogEvent = {
  category: "ShareChat_Fixed_App_Download_GetApp",
  action: "ShareChat_Fixed_Winzo_Gold_GetApp",
  label: "ShareChat_Fixed_Download_Detail_GetApp",
  fbEvent: "ShareChat_Fixed_APK_Download",
  value: 1,
};

export const GetAppFBFixedDownloadClick: LogEvent = {
  category: "FB_Fixed_App_Download_GetApp",
  action: "FB_Fixed_Winzo_Gold_GetApp",
  label: "FB_Fixed_Download_Detail_GetApp",
  fbEvent: "FB_Fixed_APK_Download",
  value: 1,
};

export const getGameDetailsHeaderEvent = (prefix: string): LogEvent => {
  return {
    category: prefix + "_Header_Download",
    action: prefix + "_Winzo_Gold_Header",
    label: prefix + "_Download_Detail_Header",
    fbEvent: prefix + "_Header_APK_Download",
    value: 1,
  };
};

export const getGameDetailsBannerEvent = (prefix: string): LogEvent => {
  return {
    category: prefix + "_Download",
    action: "Winzo_Gold_" + prefix,
    label: "Download_Detail_" + prefix,
    fbEvent: prefix + "_APK_Download",
    value: 1,
  };
};

export const getGameDetailsFixedEvent = (prefix: string): LogEvent => {
  return {
    category: prefix + "_Fixed_Download",
    action: "Winzo_Gold_" + prefix + "_Fixed",
    label: "Download_Detail_" + prefix + "_Fixed",
    fbEvent: prefix + "_Fixed_APK_Download",
    value: 1,
  };
};

export const getGameDetailsBadgeEvent = (prefix: string): LogEvent => {
  return {
    category: prefix + "_Badge_Download",
    action: "Winzo_Gold_" + prefix + "_Badge",
    label: "Download_Detail_" + prefix + "_Badge",
    fbEvent: prefix + "_Badge_APK_Download",
    value: 1,
  };
};

export const getGameDetailsBadgeEventIOS = (prefix: string): LogEvent => {
  return {
    category: prefix + "_Badge_Download_IOS",
    action: "Winzo_Gold_" + prefix + "_Badge_IOS",
    label: "Download_Detail_" + prefix + "_Badge_IOS",
    fbEvent: prefix + "_Badge_APK_Download_IOS",
    value: 1,
  };
};

export const getDeviceLanguageEvent = (lang: string): LogEvent => {
  return {
    category: lang + "_User_Language",
    action: "Device_Language_" + lang,
    label: "Device_language_" + lang + "_Selected",
    fbEvent: lang + "_Language_Selected",
    value: 1,
  };
};

export const getABPageviewEvent = (variant: string): LogEvent => {
  return {
    category: variant + "_PageView_Triggered",
    action: variant + "_PageView",
    label: variant + "_PageView_Label",
    fbEvent: variant + "_PageView_Fb_Pixel",
    value: 1,
  };
};

export const getPopupEvent = (): LogEvent => {
  return {
    category: "POP_UP_VIEW",
    action: "POP_UP_VIEW",
    label: "POP_UP_VIEW",
    fbEvent: "POP_UP_VIEW",
    value: 1,
  };
};

export const getAudioPlayEvent = (): LogEvent => {
  return {
    category: "AudioTooltip_Clicked",
    action: "AudioTooltip_Triggered",
    label: "AudioTooltip_Label",
    fbEvent: "AudioTooltip_FbEvent",
    value: 1,
  };
};

export const getGDCPageEvents = (variant: string): LogEvent => {
  return {
    category: `GDC_${variant}_Triggered`,
    action: `GDC_${variant}`,
    label: `GDC_${variant}_Label`,
    fbEvent: `GDC_${variant}_Fb_Pixel`,
    value: 1,
  };
};

export const GetAppAFBannerDownloadClick: LogEvent = {
  category: "GetApp_AFF_Banner_Download",
  action: "Winzo_Gold_GetApp_AFF_Banner",
  label: "Download_Detail_GetApp_AFF_Banner",
  fbEvent: "GetApp_AFF_Banner_APK_Download",
  value: 1,
};

export const GetAppAFDownloadClick: LogEvent = {
  category: "GetApp_AFF_Download",
  action: "Winzo_Gold_GetApp_AFF",
  label: "Download_Detail_GetApp_AFF",
  fbEvent: "GetApp_AFF_APK_Download",
  value: 1,
};

export const DcmHomeDownload = "DCM_Winzo_Download";
export const DcmHomeSiteWide = "DCM_Winzo_Sitewide";

export const getSMSApiSuccessEvent = (number: string): LogEvent => {
  return {
    category: "App_Link_SMS",
    action: "SMS_Send",
    label: "App_Link_Send",
    fbEvent: "App_Link_SMS",
    value: 1,
    fieldsObject: {
      Mobile: number,
    },
  };
};

export const SMSApiFailEvent: LogEvent = {
  category: "App_Link_SMS_Fail",
  action: "SMS_Send_Fail",
  label: "App_Link_Send_Fail",
  fbEvent: "App_Link_SMS_Fail",
  value: 1,
};

export const RefBannerDownloadClick: LogEvent = {
  category: "Ref_Download",
  action: "Winzo_Gold_Ref_Download",
  label: "Download_Detail_Ref",
  fbEvent: "Ref_APK_Download",
  value: 1,
};
export const RefFixedDownloadClick: LogEvent = {
  category: "Ref_Download_Floating",
  action: "Ref_Download_Floating",
  label: "Download_Detail_Ref_Floating",
  fbEvent: "Ref_APK_Download_Floating",
  value: 1,
};

export const DCMNDownloadClick = "DCMNDownload";

export const PlayStoreDownloadClick: LogEvent = {
  category: "Play_Store_App_Download",
  action: "Play_Store_Winzo_Gold",
  label: "Play_Store_Download_Detail",
  fbEvent: "Play_Store_APK_Download",
  value: 1,
};

export const ApkDownloadProgressCf: TimingEvent = {
  category: "Apk_Download_Progress_CF",
  variable: "Apk_Downloading_CF",
  value: 0, // in milliseconds
  label: "Apk_Downloading_Detail_CF",
};

export const ApkDownloadProgressEc: TimingEvent = {
  category: "Apk_Download_Progress_EC",
  variable: "Apk_Downloading_EC",
  value: 0, // in milliseconds
  label: "Apk_Downloading_Detail_EC",
};

export const GetAppFacebookDownloadClick: LogEvent = {
  category: "Facebook_App_Download_GetApp",
  action: "Facebook_Winzo_Gold_GetApp",
  label: "Facebook_Download_Detail_GetApp",
  fbEvent: "Facebook_APK_Download",
  value: 1,
};

export const CareerPageClick: LogEvent = {
  category: "Career_Page_Visited",
  action: "Career_Page_Clicked",
  label: "Career_Page_Clicked",
  fbEvent: "Career_Page_Visited",
  value: 1,
};

export const SuperstarPageFormClick: LogEvent = {
  category: "Superstar_Page_Form",
  action: "Form_Clicked",
  label: "Clicked_Form",
  fbEvent: "Superstar_Form_Clicked",
  value: 1,
};

export const OfferPopupCardClick: LogEvent = {
  category: "OFFER_POPUP_CLICKED",
  action: "POP_UP_CLICKED",
  label: "OFFER_POP_UP",
  fbEvent: "OFFER_POPUP_CLICKED",
  value: 1,
};

export const DownloadURLWithVariant = (variant: string): LogEvent => {
  return {
    category: "DOWNLOAD_URL_" + variant,
    action: "DOWNLOAD_URL_" + variant,
    label: "DOWNLOAD_URL_" + variant,
    fbEvent: "DOWNLOAD_URL_" + variant,
    value: 1,
  };
};

export const AutoDownloadEvent = (): LogEvent => {
  return {
    category: "AUTO_DOWNLOAD_TRIGGERED" ,
    action: "AUTO_DOWNLOAD_TRIGGERED" ,
    label: "AUTO_DOWNLOAD_TRIGGERED" ,
    fbEvent: "AUTO_DOWNLOAD_TRIGGERED" ,
    value: 1,
  };
};

export const ABTestingAnalytics = (
  event: LogEvent,
  variant: string
): LogEvent => {
  const { category, action, label, fbEvent } = event;
  return {
    category: category + "_" + variant,
    action: action + "_" + variant,
    label: label + "_" + variant,
    fbEvent: fbEvent + "_" + variant,
    value: 1,
  };
};

export const getPublishWithUsEvent = (type: string): LogEvent => {
  return {
    category: type + "_Publish_Button_Triggered",
    action: type + "_Publish_Button",
    label: type + "_Publish_Button_Label",
    fbEvent: type + "_Publish_Button_Fb_Pixel",
    value: 1,
  };
};

export const WWBannerDownloadClick: LogEvent = {
  category: "World_War_Download",
  action: "Winzo_Gold_World_War",
  label: "Download_Detail_World_War",
  fbEvent: "World_War_APK_Download",
  value: 1,
};

export const WWFixedDownloadClick: LogEvent = {
  category: "World_War_Fixed_Download",
  action: "Winzo_Gold_World_War_Fixed",
  label: "Download_Detail_World_War_Fixed",
  fbEvent: "World_War_Fixed_APK_Download",
  value: 1,
};

// New Common Events for download buttons

export const BannerDownloadClickEvent: LogEvent = {
  category: "Banner_Download",
  action: "Winzo_Gold_Banner_Download",
  label: "Detail_Banner_Download",
  fbEvent: "Banner_APK_Download",
  value: 1,
};

export const FixedDownloadClickEvent: LogEvent = {
  category: "Fixed_Download",
  action: "Winzo_Gold_Fixed_Download",
  label: "Detail_Fixed_Download",
  fbEvent: "Fixed_APK_Download",
  value: 1,
};

export const BadgeDownloadClickEventIOS: LogEvent = {
  category: "Badge_IOS_Download",
  action: "Winzo_Gold_Badge_IOS_Download",
  label: "Detail_Badge_IOS_Download",
  fbEvent: "Badge_IOS_APK_Download",
  value: 1,
};

export const BadgeDownloadClickEventAPK: LogEvent = {
  category: "Badge_APK_Download",
  action: "Winzo_Gold_Badge_APK_Download",
  label: "Detail_Badge_APK_Download",
  fbEvent: "Badge_APK_Download",
  value: 1,
};

export const LanguageToggleClick: LogEvent = {
  category: "Toggle_Clicked",
  action: "Language_Toggle_Clicked",
  label: "Language_Toggle_Clicked",
  fbEvent: "Language_Toggle_Clicked",
  value: 1,
};

export const IdentifyLanguageClick = (type: string): LogEvent => {
  return {
    category: type + "_Language_Clicked",
    action: type + "_Language_Option_Clicked",
    label: type + "_Language_Option_Clicked",
    fbEvent: type + "_Language_Option_Clicked",
    value: 1,
  };
};

export const HowToInstallVideoEvent = (variant: string): LogEvent => {
  return {
    category: variant + "_HowToInstallVideo_Event",
    action: variant + "_HowToInstallVideo_Event",
    label: variant + "_HowToInstallVideo_Event",
    fbEvent: variant + "_HowToInstallVideo_Event",
    value: 1,
  };
};

export const iosCtaColorEvent = (variant: string): LogEvent => {
  let color = variant === VariantA ? "black" : "white"
  return {
    category: `Pageview_iOS_${color}_CTA`,
    action: `Pageview_iOS_${color}_CTA`,
    label: `Pageview_iOS_${color}_CTA`,
    fbEvent: `Pageview_iOS_${color}_CTA`,
    value: 1,
  };
};
export const iosCtaColorClickEvent = (variant: string): LogEvent => {
  let color = variant === VariantA ? "black" : "white"
  return {
    category: `Download_Click_iOS_${color}_CTA`,
    action: `Download_Click_iOS_${color}_CTA`,
    label: `Download_Click_iOS_${color}_CTA`,
    fbEvent: `Download_Click_iOS_${color}_CTA`,
    value: 1,
  };
};

export const IdentifyCountrySwitch = (countrySelected: string): LogEvent => {
  return {
    category: "Country_Switch",
    action: `${countrySelected}_Selected`,
    label: `${countrySelected}_Selected`,
    fbEvent: `${countrySelected}_Selected_FB`,
    value: 1,
  };
};

export const StartNowOnMobile: LogEvent = {
  action: "START_NOW_MOBILE",
  fbEvent:"START_NOW_MOBILE",
};

export const SendOTP: LogEvent = {
  action: "SEND_OTP",
  fbEvent: "SEND_OTP",
};

export const VerifyOTP: LogEvent = {
  action: "VERIFY_OTP",
  fbEvent:"VERIFY_OTP",
};

export const RegisterNow: LogEvent = {
  action: "REGISTER_NOW",
  fbEvent:"REGISTER_NOW",
};

export const CopyLink: LogEvent = {
  action: "COPY_LINK",
  fbEvent:"COPY_LINK",
};

export const Withdraw: LogEvent = {
  action: "WITHDRAW_CLICK",
  fbEvent:"WITHDRAW_CLICK",
};

export const ResendOTP: LogEvent = {
  action: "RESEND_OTP",
  fbEvent:"RESEND_OTP",
};

export const DownloadNow: LogEvent = {
  action: "DOWNLOAD_NOW_SUPERSTAR",
  fbEvent:"DOWNLOAD_NOW_SUPERSTAR",
};


export const YoutubeVideoClick: LogEvent = {
  action: "YOUTUBE_VIDEO_CLICK",
  fbEvent:"YOUTUBE_VIDEO_CLICK",
};

export const JoinTelegram: LogEvent = {
  action: "JOIN_TELEGRAM_CLICK",
  fbEvent:"JOIN_TELEGRAM_CLICK",
};

export const MuteHowToVideo: LogEvent = {
  action: "MUTE_HOWTO_VIDEO"
}

export const HowToVideoClose: LogEvent = {
  action: "HOWTO_VIDEO_CLOSE"
}

export const HowToVideoPlayback: LogEvent = {
  action: "HOWTO_VIDEO_PLAYBACK"
}

export const HowToVideoOpen: LogEvent = {
  action: "How_to_install_old_flow_triggered"
}

export const HowToStoryOpen: LogEvent = {
  action: "How_to_install_new_flow_triggered"
}

export const HowToStoryClose: LogEvent = {
  action: "How_to_install_new_flow_cross"
}

export const HowToStoryNextFrame: LogEvent = {
  action: "How_to_install_new_flow_next_frame"
}

export const HowToStoryPrevFrame: LogEvent = {
  action: "How_to_install_new_flow_previous_frame"
}

export const HowToStoryCompleted: LogEvent = {
  action: "How_to_install_new_flow_completed"
}