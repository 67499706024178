
const zoLogger = (() => {
    const checkIfLogsEnabled = () => {
        return process.env.LOGS_ENABLED;
    }

    const isDev = process.env.NEXT_PUBLIC_ENV === 'development';    
    
    const print = ( isProd=false, ...messages ) => {
        if(isProd){
            console.log(...messages)
            return
        }
        if( checkIfLogsEnabled() || isDev ) {
            console.log(...messages);
        }
    };
    
    return {
        log:(...args)=>print(false,...args),
        productionLog:(...args)=>print(true,...args)
    };
})();

export default zoLogger;