import { LogEvent, TimingEvent } from "../types/Analytics";

abstract class BaseAnalytics {

    abstract logEvent(event: LogEvent, args:Object): void

    abstract timingEvent(event: TimingEvent): void

}

export default BaseAnalytics
