export const MAX_FILE_LIMIT = 5;
export const MAX_FILE_SIZE = 1024 * 5;

export const FORM_ERRORS = {
  REQUIRED_ERROR_MESSAGE: "This field is required",
  EMAIL_ERROR_MESSAGE: "Invalid Email",
  MOBILE_ERROR_MESSAGE: "Invalid Number",
  URL_ERROR_MESSAGE: "Invalid Url",
  FILE_ERROR_MESSAGE: "Max file size limit exceeded",
  FILE_TYPE_ERROR_MESSAGE: "File type must be Image, Video or PDF",
};

export const FORM_REGEX = {
  EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
};
