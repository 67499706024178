export enum GRIEVANCE_FORM_TYPES {
  RADIO = "radio",
  TEXT = "text",
  URL = "url",
  EMAIL = "email",
  GOV_EMAIL = "gov-email",
  DROPDOWN = "dropdown",
  NUMBER = "number",
  MOBILE = "mobile",
  FILE = "file",
  DATE = "date",
  DATETIME = "dateTime",
  CHECKBOX = "checkbox",
  PLAINTEXT = "plainText",
  FORM = "form",
  TEXTBOX = "textarea",
}


export enum HOW_TO_INSTALL_FLOW {
  VIDEO = "video",
  STORY = "story",
}

export enum COLORMODE {
  LIGHT = 0,
  DARK = 1,
}