import 'bootstrap/dist/css/bootstrap.min.css';
import type {AppProps} from "next/app";
import Head from "next/head";
import {NextRouter, useRouter} from "next/router";
import React, {useEffect} from "react";
import "regenerator-runtime/runtime";
import {GoogleTagManager} from '@next/third-parties/google'
import "./global.css"
import {Saira} from "next/font/google";
import { setIsIOS, setIsMobileDevice, setPageURL } from '../../lib/utils/constants/PagesConstants';
import { getWebsiteEnvironment } from '../../lib/utils/constants/EnvironmentConstants';
import { CommonContext } from '../../lib/context/CommonContext';

const saira = Saira({
    subsets: ['latin'],
    weight: ['400', '300', '500', '600', '700', '800', '900'],
    variable: "--font-primary"
})

function MyApp({Component, pageProps}: AppProps) {
    const router: NextRouter = useRouter();

    useEffect((): void => {
        setIsMobileDevice();
        setIsIOS();
    }, []);

    setPageURL(pageProps?.apiData?.pageName);

    useEffect(() => {
        setPageURL(pageProps?.apiData?.pageName);
    }, [router.asPath, pageProps?.apiData?.pageName]);

    // Should page index or not (SEO)
    function shouldAddRobotTag() {
        return !getWebsiteEnvironment().shouldIndex;
    }

    return (
        <>
            <style jsx global>{`
              :root {
                --font-primary: ${saira.style.fontFamily};
              }
            `}</style>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                {pageProps?.apiData?.content?.page_title &&
                    <title>{pageProps.apiData.content.page_title}</title>
                }
            </Head>

            {
                shouldAddRobotTag() && (
                    <meta name="robots" content="noindex, nofollow"/>
                )
            }
            <GoogleTagManager gtmId={getWebsiteEnvironment().gtmId}/>
            {pageProps?.apiData?.pageName && <CommonContext deviceArchitectureMapping={pageProps.deviceArchitectureMapping} locale={pageProps.apiData.locale}
                            appVersions={pageProps.appVersions} abTestingData={pageProps.abTestingData} brandModelData={pageProps.brandModelData}
                             apkDisabled={pageProps.apiData?.commonData?.fixedDownloadButton?.disabled || false}>
                <Component {...pageProps}/>
            </CommonContext>}
        </>
    );
}

export default MyApp;

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }